import {
	getBinanceMarketType,
	parseBinanceCandleData,
	sortBinanceSymbols,
	translateBinanceResolution,
} from './helpers.js';

var moment = require('moment');

var rp = require('request-promise').defaults({json: true});

// 예제 = https://min-api.cryptocompare.com/data/v2/histohour?fsym=BTC&tsym=USD&limit=10
const api_root = 'https://min-api.cryptocompare.com';
const history = {};
const cryptoCompareLimit = 2000;
const binanceLimit = 1000;
const upbitLimit = 200;

// 요청시 API 마다 limit이 다르기 때문에 쪼개서 호출해주기위한 시간을 만듦
function maketoTsList(from, to, resolution, type, exchange) {
  let limit = (type == 'COINM') && ((resolution == '1D') || (resolution == '1W') || (resolution == '1M')) ? 200 : binanceLimit;

  if (exchange == 'Upbit') {
    limit = upbitLimit;
  }
  // let scale = resolution === '1D' ? 86400 : resolution >= 60 ? 3600 : 60
  let scale = 60;
  if (resolution === '1D')
    scale = 86400;
  else if (resolution === '1W')
    scale = 86400 * 7;
  else if (resolution === '1M')
    scale = 86400 * 30;
  else
    scale = parseInt(resolution, 10) * 60;

  let arr = [];
  let numCall = Math.ceil((to - from) / (limit * scale));
  for (let i = 0; i < numCall; i++) {
    arr = [...arr, to - (i * limit * scale)];
  }
  arr = [...arr, from];
  let result = arr.reverse();
  // pair로 만든다
  let res = [];
  for (let i = 0; i < result.length - 1; i++) {
    res = [...res,
      {
        startTime: arr[i],
        endTime: arr[i + 1],
      }];
  }

  return res;
}


export default {
  history: history,
  getBars: async function (symbolInfo, resolution, from, to, first, limit) {
    // name에 symbol이 들어가도록, exchange 있으니까 이 두개가 기준 full_name을 ticker로 쓴다
    // tester(symbolInfo, resolution, from, to, first, limit)
    // 1. 먼저 binance인지 upbit인지에 따라서 달라져야함
    // 2. 어떤 종류인지 나누어야함
    let url = sortBinanceSymbols(symbolInfo.full_name);
    // let offset = to- from
    // to = to + offset
    // from = from - 10000000
    // let arr = [to]
    let arr = maketoTsList(from, to, resolution, getBinanceMarketType(symbolInfo.full_name), symbolInfo.exchange);

    let symbolName = '';
    let pairName = '';
    let contractType = '';
    const fullNameSplit = symbolInfo.full_name.split(':');
    if (fullNameSplit.length === 3) {
      if (fullNameSplit[1].toUpperCase() === 'SPOT') {
        symbolName = symbolInfo.name;
        pairName = null;
        contractType = null;
      } else {
        if (symbolInfo.contract_type === 'PERPETUAL') {
          // symbolName = symbolInfo.name;
          pairName = symbolInfo.name.split('_')[0];
          contractType = 'PERPETUAL';
        } else {
          // symbolName = null;
          pairName = symbolInfo.name.split('_')[0];
          contractType = symbolInfo.contract_type;
        }
      }
    } else {
      return new Promise((res, rej) => []);
    }

    let st = {};
    if (symbolName) {
      st.symbol = symbolName;
    }
    if (pairName) {
      st.pair = pairName;
    }
    if (contractType) {
      st.contractType = contractType;
    }

    let qt = {
      ...st,
      interval: translateBinanceResolution(resolution),
      startTime: from * 1000,
      endTime: to * 1000,
      limit: binanceLimit,
    };

    let options = arr.map(d => {
      let qs = {...qt, startTime: d.startTime * 1000, endTime: d.endTime * 1000};
      return {
        url: `${url}`,
        qs,
      };
    });
    let promises = options.map(o => rp(o));
    let result = [];

    return Promise.all(promises).then(datas => {
      datas.forEach(data => {

        if (data.length) {

          var bars = data.map(d => {
            return parseBinanceCandleData(d);
          });
          // bars = bars.filter(d => (d.time >= (arr[index].startTime * 1000)) && (d.time <= (arr[index].endTime * 1000)))
          result = result.concat(bars);
          return [];
        } else {
          return [];
        }
      });
    }).then(d => {
      result = result.filter(d => d.time > from * 1000);
      if (first) {
        var lastBar = result[result.length - 1];
        history[symbolInfo.name] = {lastBar: lastBar};
      }
      return result;
    });
  },
};
