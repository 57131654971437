// api/stream.js
import {getBinanceMarketType, initializeWebSocket, Updatable} from './helpers.js';
import historyProvider from './historyProvider.js';
// import WebSocket from 'ws'

// var socket_url = 'wss://streamer.cryptocompare.com'
// var socket = io(socket_url)
// keep track of subscriptions
var _subs = [];

// <symbol>@trade
var binance_url = {
  Future: 'wss://fstream.binance.com/ws',
  Spot: 'wss://stream.binance.com:9443/ws',
  COINM: 'wss://dstream.binance.com/ws',
};

var binance_handler = {
  Future: binanceFutureMessageHandler,
  Spot: binanceSpotMessageHandler,
  COINM: binanceCOINMMessageHandler,
};

var binance_ws = {
  Future: initializeWebSocket(new WebSocket(binance_url['Future']), binanceFutureMessageHandler),
  Spot: initializeWebSocket(new WebSocket(binance_url['Spot']), binanceSpotMessageHandler),
  COINM: initializeWebSocket(new WebSocket(binance_url['COINM']), binanceCOINMMessageHandler),
};

var upbit_url = 'wss://api.upbit.com/websocket/v1';

for (let key in binance_ws) {
  const socket = binance_ws[key];

  let isFirst = true;

  socket.addEventListener('close', () => {
    if (isFirst) {
      isFirst = false;
      return;
    }

    setTimeout(() => {
      binance_ws[key] = initializeWebSocket(new WebSocket(binance_url[key]), binance_handler[key]);
    }, 1000);
  });

  socket.addEventListener('error', err => {
    console.error('==== Web Socket error', err);
    socket.close();
  });
}

// binance_ws['Future'].addEventListener('message', binanceFutureMessageHandler)
// binance_ws['Spot'].addEventListener('message', binanceSpotMessageHandler)
// binance_ws['COINM'].addEventListener('message', binanceCOINMMessageHandler)

function hasChannelSubscription(channelString) {
  // 중복된 subscription인지 검사하기
  var subIndex = _subs.findIndex(e => e.channelString === channelString);
  if (subIndex === -1) {
    return false;
  } else {
    return true;
  }
}

// _subs으로부터 구독 제거
function removeSub(channelString) {

  var subIndex = _subs.findIndex(e => e.channelString === channelString);

  // 만약 해당 구독에 websocket이 존재한다면
  if (_subs[subIndex].ws)
    _subs[subIndex].ws.close();

  _subs.splice(subIndex, 1);
}


export default {
  subscribeBars: function (symbolInfo, resolution, updateCb, uid, resetCache) {
    const channelString = createChannelString(symbolInfo);
    let ws;

    if (hasChannelSubscription(channelString)) {
      // 기존 channelString을 구독했으면
      // 제거 후에 새로운 구독으로 등록한다
      removeSub(channelString);

    } else {
      let payload = JSON.stringify({
        method: 'SUBSCRIBE',
        params: [`${symbolInfo.name.toLocaleLowerCase()}@trade`],
        id: new Date().getTime(),
      });
      binance_ws[getBinanceMarketType(symbolInfo.full_name)].send(payload);
    }

    var newSub = {
      channelString,
      uid,
      resolution,
      symbolInfo,
      lastBar: historyProvider.history[symbolInfo.name].lastBar,
      listener: updateCb,
      ws: ws,
    };
    _subs.push(newSub);
  },

  unsubscribeBars: function (uid) {
    var subIndex = _subs.findIndex(e => e.uid === uid);
    if (subIndex === -1) {
      return;
    }
    var sub = _subs[subIndex];

    let payload = JSON.stringify({
      method: 'UNSUBSCRIBE',
      params: [`${sub.symbolInfo.name.toLocaleLowerCase()}@trade`],
      id: new Date().getTime(),
    });
    binance_ws[getBinanceMarketType(sub.symbolInfo.full_name)].send(payload);
    _subs.splice(subIndex, 1);
  },
};

function binanceFutureMessageHandler(e) {
  return binanceMessageHandler(e, 'Future');
}

function binanceSpotMessageHandler(e) {
  return binanceMessageHandler(e, 'Spot');
}

function binanceCOINMMessageHandler(e) {
  return binanceMessageHandler(e, 'COINM');
}

function binanceMessageHandler(e, type) {
  let parsed = JSON.parse(e.data);
  const data = {
    exchange: 'Binance',
    sym: parsed.s,
    trade_id: parsed.t,
    ts: parsed.T / 1000,
    volume: parseFloat(parsed.q),
    price: parsed.p,
  };

  // full_name과 동일한 포맷으로
  const channelString = `${data.exchange}:${type}:${data.sym}`;

  const sub = _subs.find(e => e.channelString === channelString);

  if (sub) {
    // disregard the initial catchup snapshot of trades for already closed candles
    if (data.ts < sub.lastBar.time / 1000) {
      return;
    }

    // if(Updatable.canUpdate()) {
    var _lastBar = updateBar(data, sub);

    if (_lastBar) {
      _lastBar.forEach(lastBar => sub.listener(lastBar));
      sub.lastBar = _lastBar[_lastBar.length - 1];
    }
    // send the most recent bar back to TV's realtimeUpdate callback
    // sub.listener(_lastBar)
    // update our own record of lastBar
    // sub.lastBar = _lastBar
    // Updatable.blockUpdate();
    // }
  }
}

// Take a single trade, and subscription record, return updated bar
function updateBar(data, sub) {
  var lastBar = sub.lastBar;
  let resolution = sub.resolution;
  let mulVal = 60;
  if (resolution.includes('D')) {
    // 1 day in minutes === 1440
    resolution = 1440;
  } else if (resolution.includes('W')) {
    // 1 week in minutes === 10080
    resolution = 10080;
  } else if (resolution.includes('S')) {
    resolution = +resolution.replace('S', '');
    mulVal = 1;
  }

  var coeff = resolution * mulVal;
  var rounded = Math.floor(data.ts / coeff) * coeff;
  var lastBarSec = lastBar.time / 1000;
  var _lastBar = [];


  if (rounded > lastBarSec) {
    // create a new candle, use last close as open **PERSONAL CHOICE**
    _lastBar.push(sub.lastBar);
    _lastBar.push({
      time: rounded * 1000,
      open: lastBar.close,
      high: lastBar.close,
      low: lastBar.close,
      close: data.price,
      volume: data.volume,
    });
  } else {
    if (!Updatable.canUpdate()) return null;

    // update lastBar candle!
    if (data.price < lastBar.low) {
      lastBar.low = data.price;
    } else if (data.price > lastBar.high) {
      lastBar.high = data.price;
    }

    lastBar.volume += data.volume;
    lastBar.close = data.price;
    _lastBar.push(lastBar);
    Updatable.blockUpdate();
  }
  return _lastBar;
}

// takes symbolInfo object as input and creates the subscription string to send to CryptoCompare
function createChannelString(symbolInfo) {
  return symbolInfo.full_name;
}
